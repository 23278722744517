import React, { useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from 'axios';

interface DemoFormProps {
  layout?: string;
}

const DemoForm: React.FC<DemoFormProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // State
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [requestError, setRequestError] = useState('');
  const [loading, setLoading] = useState(false);

  // Handler
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setRequestError('');

    const { email, message } = formData;

    setLoading(true);
    try {
      await axios.post(
        '/api/sendDemoRequest',
        `Request: ${message}, Email: ${email}`
      );
      toast.success(t('landing.requestdemo.success'), {
        theme: 'colored'
      });
      setFormData({
        name: '',
        email: '',
        message: ''
      });
      navigate({
        pathname: '/'
      });
    } catch (error) {
      console.error('Error:', error);
      toast.error(t('landing.requestdemo.error'), {
        theme: 'colored'
      });
    }
    setLoading(false);
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {requestError && (
        <Form.Text className="text-danger">{requestError}</Form.Text>
      )}
      <Form.Group className="mb-3">
        <Form.Label>{t('landing.requestdemo.name.label')}</Form.Label>
        <Form.Control
          placeholder={t('landing.requestdemo.name.placeholder')}
          value={formData.name}
          name="name"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('landing.requestdemo.email.label')}</Form.Label>
        <Form.Control
          placeholder={t('landing.requestdemo.email.placeholder')}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>{t('landing.requestdemo.message.label')}</Form.Label>
        <Form.Control
          as="textarea"
          placeholder={t('landing.requestdemo.message.placeholder')}
          value={formData.message}
          name="message"
          onChange={handleFieldChange}
          rows={3}
        />
      </Form.Group>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.name || loading}
        >
          {loading ? (
            <>
              <Spinner animation="border" size="sm" />
              {t('landing.requestdemo.ctaTextDemo')}
            </>
          ) : (
            <>{t('landing.requestdemo.ctaTextDemo')}</>
          )}
        </Button>
      </Form.Group>
    </Form>
  );
};

export default DemoForm;
