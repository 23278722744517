import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Image } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';

const Feature = ({
  icon,
  iconText,
  isFirst,
  color,
  title,
  description,
  image,
  inverse,
  index,
  children
}) => {
  const { ref, inView } = useInView({
    triggerOnce: false // Change this to false if you want the animation to trigger again whenever it comes in view
  });

  const featureClassName = inView
    ? 'landing-show'
    : index % 2 == 0
    ? 'feature-hidden-right'
    : 'feature-hidden-left';

  const imageClassName = inView
    ? 'landing-show'
    : index % 2 == 0
    ? 'feature-hidden-left'
    : 'feature-hidden-right';

  return (
    <Row
      className={classNames('flex-center', {
        'mt-7': !isFirst,
        'mt-8': isFirst
      })}
      ref={ref}
    >
      <Col
        md={{ order: inverse ? 2 : 0, span: 6 }}
        lg={5}
        xl={4}
        className={
          classNames({ 'pe-lg-6': inverse, 'ps-lg-6': !inverse }) +
          ' ' +
          imageClassName
        }
      >
        <Image fluid className="px-6 px-md-0" src={image} alt="" />
      </Col>
      <Col md lg={5} xl={4} className={'mt-4 mt-md-0 '}>
        <h5 className={`text-${color} ${featureClassName}`}>
          <FontAwesomeIcon icon={icon} className="me-2" />
          {iconText}
        </h5>
        <h3 className={featureClassName} style={{ transitionDelay: '100ms' }}>
          {title}
        </h3>
        <p className={featureClassName} style={{ transitionDelay: '200ms' }}>
          {description}
        </p>
        {children}
      </Col>
    </Row>
  );
};

Feature.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  iconText: PropTypes.string.isRequired,
  isFirst: PropTypes.bool,
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  inverse: PropTypes.bool,
  children: PropTypes.node
};

export default Feature;
