import React from 'react';
import NavbarPageRoute from './NavbarPageRoute';
import { useTranslation } from 'react-i18next';

const NavbarTopDropDownMenus = () => {
  const { t } = useTranslation();

  /*const {
    config: { isNavbarVerticalCollapsed, showBurgerMenu },
    setConfig
  } = useContext(AppContext);

  const handleDropdownItemClick = () => {
    if (isNavbarVerticalCollapsed) {
      setConfig('navbarCollapsed', !isNavbarVerticalCollapsed);
    }
    if (showBurgerMenu) {
      setConfig('showBurgerMenu', !showBurgerMenu);
    }
  };*/

  return (
    <>
      <NavbarPageRoute title={t('navbar.about')} route="/about" />
      {/*<NavbarDropdown title={t('navbar.product')}>
        <Dropdown.Item
          as={Link}
          to="/product/cosmos"
          onClick={handleDropdownItemClick}
          style={{
            fontSize: '2rem'
          }}
        >
          {t('navbar.cosmos')}
        </Dropdown.Item>
      </NavbarDropdown>

      <NavbarPageRoute title={t('navbar.contact')} route="/contact" />
      <NavbarPageRoute title={t('navbar.team')} route="/team" />
      <NavbarPageRoute title={t('navbar.careers')} route="/careers" />
        <NavbarPageRoute title={t('navbar.blog')} route="/blog" />*/}
    </>
  );
};

export default NavbarTopDropDownMenus;
