import { createContext } from 'react';

export interface ConfigType {
  isFluid: boolean;
  isRTL: boolean;
  isDark: boolean;
  navbarPosition: string;
  showBurgerMenu: boolean;
  currency: string;
  isNavbarVerticalCollapsed: boolean;
  navbarStyle: string;
}

export interface AppContextType {
  config: ConfigType;
  setConfig: (key: string, value: any) => void;
  configDispatch: React.Dispatch<any>;
}

const AppContext = createContext<AppContextType>(null as any);

export default AppContext;
