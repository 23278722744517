import React, { useEffect } from 'react';
import NavbarStandard from './landing/NavbarStandard';
import Section from 'components/common/Section';
import { Col, Row } from 'react-bootstrap';
import FooterStandard from './landing/FooterStandard';

const Imprint = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <NavbarStandard transparent={false} />
      <Section>
        <Row className="justify-content-center">
          <Col lg={8} className="py-5 mt-5">
            <h3>Angaben gemäß § 5 Telemediengesetz („TMG“)</h3>
            <p>
              Hausable Building Intelligence UG (haftungsbeschränkt)
              <br />
              Kurfürsten-Anlage 52
              <br />
              69115 Heidelberg
            </p>
            <p>
              <strong>Vertreten durch:</strong>
              <br />
              Geschäftsführer: Bastian Guckenhan
            </p>
            <p>
              <strong>Kontakt:</strong>
              <br />
              E-Mail: team@hausable.de
            </p>
            <p>
              <strong>Registereintrag:</strong>
              <br />
              Eintragung im Registergericht: Mannheim
              <br />
              Registernummer: HRB 750167
            </p>
            <p>
              <strong>Umsatzsteuer-ID:</strong>
              <br />
              Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:
              DE366853779
            </p>
            <p>
              <h3>Datenschutz</h3>
              Die Nutzung unserer Webseite ist in der Regel ohne Angabe
              personenbezogener Daten möglich. Soweit auf unseren Seiten
              personenbezogene Daten (beispielsweise Name, Anschrift oder
              eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
              stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
              ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir weisen
              darauf hin, dass die Datenübertragung im Internet (z.B. bei der
              Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
              lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
              nicht möglich. Der Nutzung von im Rahmen der Impressumspflicht
              veröffentlichten Kontaktdaten durch Dritte zur Übersendung von
              nicht ausdrücklich angeforderter Werbung und
              Informationsmaterialien wird hiermit ausdrücklich widersprochen.
              Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
              Schritte im Falle der unverlangten Zusendung von
              Werbeinformationen, etwa durch Spam-Mails, vor.
            </p>
            <p>
              <h3>Haftungsausschluss</h3>
              Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
              Haftung für die Inhalte externer Links. Für den Inhalt der
              verlinkten Seiten sind ausschließlich deren Betreiber
              verantwortlich.
            </p>
            <p>
              <h3>Urheberrecht</h3>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
              diesen Seiten unterliegen dem deutschen Urheberrecht. Die
              Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
              Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
              schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            </p>
          </Col>
        </Row>
      </Section>
      <FooterStandard />
    </>
  );
};

export default Imprint;
