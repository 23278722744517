import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import AppContext from 'context/Context';
import { useTranslation } from 'react-i18next';

const LandingRightSideNavItem = () => {
  const { i18n } = useTranslation();

  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);

  const [currentLanguage, setCurrentLanguage] = useState(i18n.language || 'en');

  const handleLanguageChange = () => {
    const newLanguage = currentLanguage === 'en' ? 'de' : 'en';
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
  };

  return (
    <Nav navbar className="ms-auto">
      <Nav.Item as={'li'}>
        <Nav.Link
          className="pe-2 theme-switch-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip
                style={{ position: 'fixed' }}
                id="ThemeColor"
                className="d-none d-lg-block"
              >
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
          >
            <span>
              <div className="theme-switch-toggle-label">
                <FontAwesomeIcon
                  icon={isDark ? 'sun' : 'moon'}
                  className="me-2"
                />
              </div>
              <p className="d-lg-none mb-0">
                {isDark ? 'Switch to light theme ' : 'Switch to dark theme'}
              </p>
            </span>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>

      <Nav.Item as={'li'}>
        <Nav.Link
          className="fw-semi-bold nav-link"
          onClick={handleLanguageChange}
        >
          {currentLanguage === 'en' ? 'Deutsch' : 'English'}
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default LandingRightSideNavItem;
