import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Background from './Background';
import { Container } from 'react-bootstrap';

interface IPosition {
  x: string;
  y: string;
}

interface ISectionProps {
  fluid?: boolean;
  bg?: string;
  image?: string;
  overlay?: boolean | string;
  position?: string | IPosition;
  video?: string[];
  bgClassName?: string;
  className?: string;
  children?: ReactNode;
}

const Section: React.FC<ISectionProps> = ({
  fluid = false,
  bg,
  image,
  overlay,
  position,
  video,
  bgClassName,
  className,
  children,
  ...rest
}) => {
  const bgProps = { image, overlay, position, video };
  bgClassName && ((bgProps as any).className = bgClassName);

  return (
    <section className={classNames({ [`bg-${bg}`]: bg }, className)} {...rest}>
      {image && <Background {...bgProps} />}
      <Container fluid={fluid}>{children}</Container>
    </section>
  );
};

export default Section;
