import React from 'react';
import Hero from './Hero';
import NavbarStandard from './NavbarStandard';
import Partners from './Partners';
import Features from './Features';
import Services from './Services';
import Cta from './Cta';
import FooterStandard from './FooterStandard';

const Landing = () => {
  return (
    <>
      <NavbarStandard />
      <Hero />
      <Features />
      <Services />
      {/*<Testimonial />*/}
      <Partners />
      <Cta />
      <FooterStandard />
    </>
  );
};

export default Landing;
