import React from 'react';
import { useTranslation } from 'react-i18next';
import SplitLayout from 'layouts/SplitLayout';
import bgImg from 'assets/img/generic/14.jpg';
import Flex from 'components/common/Flex';
import NavbarStandard from '../landing/NavbarStandard';
import DemoForm from './DemoForm';

const Demo = () => {
  const { t } = useTranslation();
  return (
    <>
      <NavbarStandard />
      <SplitLayout bgProps={{ image: bgImg, position: '50% 20%' }}>
        <Flex alignItems="center" justifyContent="between">
          <h3>{t('landing.requestdemo.title')}</h3>
        </Flex>
        <DemoForm layout="split" />
      </SplitLayout>
    </>
  );
};

export default Demo;
