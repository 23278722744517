import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';

const CardValues = ({
  media,
  title,
  description,
  children,
  visibilityClassName,
  index
}) => {
  return (
    <Card
      className={`card-span h-100 ${visibilityClassName}`}
      style={{ transitionDelay: `${index * 100}ms` }}
    >
      <div className="card-span-img">
        <FontAwesomeIcon
          icon={media.icon}
          className={className(
            { [`text-${media.color}`]: media.color },
            media.className
          )}
        />
      </div>
      <Card.Body className="pt-6 pb-4">
        <h5
          className={className(
            { [`text-${media.color}`]: media.color },
            'mb-2',
            'text-uppercase'
          )}
        >
          {title}
        </h5>
        {description && <p>{description}</p>}
        {children}
      </Card.Body>
    </Card>
  );
};

CardValues.propTypes = {
  media: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
    color: PropTypes.string.isRequired,
    className: PropTypes.string
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node
};

export default CardValues;
