import Section from 'components/common/Section';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import React from 'react';
import bg from 'assets/img/generic/13.jpg';

const AboutHero = () => {
  const { t } = useTranslation();

  return (
    <Section
      className="py-0 overflow-hidden light"
      image={bg}
      position="center bottom"
      overlay
    >
      <Row>
        <Col>
          <h1 className="text-light text-center py-8 mt-10">
            {t('about.title')}
          </h1>
        </Col>
      </Row>
    </Section>
  );
};

export default AboutHero;
