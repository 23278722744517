import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      de: {
        translation: {
          hausable: 'Hausable',
          navbar: {
            dashboard: 'Dashboard',
            pages: 'Seiten',
            modules: 'Module',
            documentation: 'Dokumentation',
            about: 'Über uns',
            contact: 'Kontakt',
            inventory: 'Inventar',
            sales: 'Vertrieb',
            api: 'API',
            guides: 'Anleitungen',
            product: 'Produkt',
            product1: 'Produkt 1',
            product2: 'Produkt 2',
            cosmos: 'Cosmos',
            team: 'Team',
            careers: 'Karriere',
            blog: 'Blog'
          },
          landing: {
            current_language: 'Deutsch',
            becomeProButton: 'Werde ein Profi',
            bring: 'Bringen Sie ',
            adjectives: ['Intelligenz', 'Effizienz', 'Kontrolle'],
            toYourBuilding: 'in Ihre Gebäude',
            transformApartmentBuilding:
              'Verschaffen Sie sich einen Überblick über die Energieeffizienz Ihrer Gebäude mit unserer All-in-One-Lösung, <span>Cosmos</span>. Verwandeln Sie Ihr Heizsystem in ein datengesteuertes System.',
            ctaText:
              '<span>Springen</span> Sie in die Zukunft der Immobilienverwaltung, noch heute.',
            requestdemo: {
              title: 'Buchen Sie eine Demo',
              name: {
                label: 'Name',
                placeholder: 'Ihr Name'
              },
              email: {
                label: 'Email',
                placeholder: 'Ihre Email'
              },
              message: {
                label: 'Nachricht',
                placeholder:
                  'Teilen Sie uns mit, warum Sie an einer Demo interessiert sind.'
              },
              ctaTextDemo: 'Buchen Sie eine Demo',
              success: 'Ihre Anfrage wurde erfolgreich gesendet.',
              error: 'Ihre Anfrage konnte nicht gesendet werden.'
            },
            playIcon: 'play',
            imgAltText: 'Landing Banner',
            features: {
              title: 'Energieeffizienz und Heizsystemsteuerung leicht gemacht',
              subtitle:
                'Navigieren Sie mit Leichtigkeit durch die Komplexität von Heizungssystemen. Unsere Lösung bietet einen ganzheitlichen und benutzerfreundlichen Ansatz, der sich auf Nachhaltigkeit und Einhaltung von Vorschriften konzentriert.',
              monitor: {
                icon: 'chart-line',
                iconText: 'ÜBERWACHEN',
                title: 'Umfassende Heizungsmanagementlösung',
                description:
                  'Unsere All-in-One-Lösung führt Ihr Heizungsmanagement in die Zukunft, mit Fokus auf Energieüberwachung, -visualisierung und -optimierung von Gebäuden.'
              },
              predict: {
                icon: 'bell',
                iconText: 'VORHERSAGEN',
                title: 'Regelbasiertes Benachrichtigungssystem',
                description:
                  'Wir bieten ein regelbasiertes Benachrichtigungssystem, das Ihnen hilft, potenzielle Probleme rechtzeitig zu erkennen und zu handeln, bevor sie eskalieren.'
              },
              manage: {
                icon: 'wrench',
                iconText: 'VERWALTEN',
                title: 'Gebäudemanagement für Nachhaltigkeit',
                description:
                  'Unsere umfassende Werkzeugpalette ermöglicht die Einhaltung von Vorschriften, Optimierung der Systemleistung und unterstützt Sie bei fundierten Entscheidungen für Ihre Nachhaltigkeitsplanung.'
              },
              assist: {
                icon: 'globe',
                iconText: 'UNTERSTÜTZEN',
                title: 'Ohne Probleme durch neue CO2-Vorschriften',
                description:
                  'Mit genauer Überwachung der CO2-Emissionen und Identifizierung von Maßnahmen zur Emissionsreduzierung helfen wir Vermietern, das neue Umfeld der Kohlenstoffpreisgestaltung zuversichtlich zu bewältigen.'
              }
            },
            services: {
              title: 'Wir liefern <span>Wert</span> für Sie',
              subtitle:
                'Wir bieten Funktionen und Dienstleistungen, die das Komplexe einfach machen und Ihnen die Kontrolle über das Energiemanagement Ihres Gebäudes geben, während wir die regulatorische Konformität gewährleisten.',
              cloudMonitoring: {
                icon: 'chart-line',
                title: 'Cloud-basiertes Gebäudemanagement & Visualisierung',
                description:
                  'Navigieren Sie mit Leichtigkeit durch die Komplexität verschiedener Heizsysteme mit unserer Cloud-basierten Plattform. Überwachen und visualisieren Sie den Energieverbrauch in Echtzeit.'
              },
              predictiveMaintenance: {
                icon: 'bell',
                title:
                  'Regelbasiertes Benachrichtigungssystem für Wartungsarbeiten',
                description:
                  'Unser System bietet ein regelbasiertes Benachrichtigungssystem, das Ihnen hilft, potenzielle Probleme auf Basis der von Ihrem Gebäudeheizsystem und anderen Sensoren gesammelten Daten vorherzusehen.'
              },
              erp: {
                icon: 'dollar-sign',
                title: 'Kostenrückerstattung und Mieterkommunikation',
                description:
                  'Sparen Sie Geld, indem Sie den Energieverbrauch optimieren und die Kosten des Systems im Rahmen ihrer Wartungskosten mit den Mietern teilen. Fördern Sie eine offene Kommunikation mit den Mietern über den Energieverbrauch und die Kosten.'
              }
            }
          },
          about: {
            title: 'Energieeffizienz, Nachhaltigkeit stärken',
            subtitle: 'Erfahren Sie mehr über unsere Reise und unser Team',
            companyDescription: {
              title: 'Unternehmensüberblick',
              story: {
                title: 'Unsere Geschichte',
                text: 'Willkommen bei Hausable, Ihrem Partner für Energieeffizienz und Nachhaltigkeit. Als Branchenführer im Bereich Energiemanagementsysteme (EMS) bieten wir <span>innovative Lösungen</span>, die auf Ihre individuellen Bedürfnisse zugeschnitten sind. Unsere Mission ist es, den Energieverbrauch zu optimieren, Kosten zu reduzieren und Umweltauswirkungen zu minimieren. Bei Hausable kombinieren wir modernste Technologie mit tiefgreifendem branchenspezifischem Fachwissen. Von der Fertigung bis zur Bildung ermöglichen wir Unternehmen, <span>datengesteuerte</span> Entscheidungen zu treffen, die das Wachstum fördern. Begleiten Sie uns auf dem Weg zu einer <span>umweltfreundlicheren</span> und effizienteren Zukunft, in der Nachhaltigkeit auf Rentabilität trifft.'
              },
              mission: {
                title: 'Unsere Mission',
                text: 'Unsere Mission ist es, das Energiemanagement zu transformieren. Wir ermöglichen Unternehmen in verschiedenen Branchen, die Energieeffizienz zu optimieren, Kosten zu reduzieren und nachhaltige Praktiken zu fördern.'
              },
              vision: {
                title: 'Unsere Vision',
                text: 'Unsere Vision ist es, ein weltweit führender Anbieter von Energiemanagementsystemen zu sein und Nachhaltigkeit und Rentabilität in verschiedenen Branchen mit Innovation und Engagement voranzutreiben. Wir arbeiten an einer Welt, in der intelligente Energieentscheidungen den Erfolg definieren.'
              }
            },
            team: {
              title: 'Unser Team',
              // Hier können Sie für jedes Teammitglied Felder wie Name, Rolle und eine kurze Beschreibung hinzufügen
              members: {
                member1: {
                  name: 'Sarah Johnson',
                  position: 'Senior Project Manager',
                  bio: 'Sarah ist eine hochqualifizierte Projektmanagerin mit über 10 Jahren Erfahrung in der Leitung komplexer Projekte von der Initiierung bis zum erfolgreichen Abschluss. Sie ist bekannt für ihre außergewöhnlichen organisatorischen Fähigkeiten, akribische Aufmerksamkeit zum Detail und ihre Fähigkeit, interdisziplinäre Teams zu führen.'
                },
                member2: {
                  name: 'John Smith',
                  position: 'Softwareentwickler',
                  bio: 'John ist ein leidenschaftlicher Softwareentwickler mit fundierten Kenntnissen in der Full-Stack-Webentwicklung. Er hat ein Talent, komplexe Ideen in elegante, benutzerfreundliche Softwarelösungen umzusetzen. John ist ein lebenslanger Lerner, der immer auf dem neuesten Stand der Programmiersprachen und Technologien bleibt.'
                },
                member3: {
                  name: 'Emily Rodriguez',
                  position: 'Marketing-Spezialistin',
                  bio: 'Emily ist eine kreative und datengetriebene Marketing-Spezialistin, die sich in der Entwicklung und Umsetzung von Marketingstrategien auszeichnet, die die Markenbekanntheit und das Kundenengagement steigern. Mit ihrem geschulten Auge für Design und ihrem analytischen Denkvermögen hat sie erfolgreich zahlreiche Marketingkampagnen auf verschiedenen digitalen Plattformen geleitet.'
                },
                member4: {
                  name: 'David Johnson',
                  position: 'Finanzanalyst',
                  bio: 'David ist ein detailorientierter Finanzanalyst mit Expertise in Budgetierung und finanzieller Prognose. Er hat ein Gespür für die Identifizierung von kostensparenden Möglichkeiten und die Optimierung finanzieller Prozesse.'
                },
                member5: {
                  name: 'Michael Wilson',
                  position: 'Verkaufsleiter',
                  bio: 'Michael ist ein ergebnisorientierter Verkaufsleiter mit nachgewiesener Fähigkeit, Verkaufsteams zum Erfolg zu führen. Er ist auf die Entwicklung effektiver Verkaufsstrategien und den Aufbau starker Kundenbeziehungen spezialisiert.'
                },
                member6: {
                  name: 'Andrew Mitchell',
                  position: 'Datenwissenschaftler',
                  bio: 'Andrew ist ein Datenwissenschaftler mit Leidenschaft für die Entdeckung wertvoller Erkenntnisse aus komplexen Datensätzen. Er ist Experte in maschinellem Lernen und statistischer Analyse, was datengetroffene Entscheidungen leicht macht.'
                },
                member7: {
                  name: 'Benjamin Turner',
                  position: 'Produktmanager',
                  bio: 'Benjamin ist ein erfahrener Produktmanager, bekannt für seine strategische Vision und Führung. Er hat erfolgreich zahlreiche Produkte eingeführt und ist bestrebt, erstklassige Benutzererlebnisse zu bieten.'
                },
                member8: {
                  name: 'Christopher White',
                  position: 'Finanzberater',
                  bio: 'Christopher ist ein sachkundiger Finanzberater, der Kunden bei der Navigation ihrer finanziellen Ziele unterstützt. Seine Expertise liegt in der Vermögensverwaltung, der Rentenplanung und den Anlagestrategien.'
                },
                member9: {
                  name: 'Daniel Harris',
                  position: 'Softwareentwickler',
                  bio: 'Daniel ist ein erfahrener Softwareentwickler, der komplexe Codierungsprobleme liebt. Er beherrscht mehrere Programmiersprachen und hat ein geschultes Auge für Details.'
                }
              }
            },
            values: {
              title: 'Unsere Leitprinzipien',
              subtitle:
                'Im Herzen unserer Mission liegt ein unerschütterliches Engagement für die Bereitstellung wegweisender Lösungen, die die Kraft der Innovation nutzen, ein tief verwurzeltes Umweltbewusstsein und eine unerschütterliche Hingabe, unseren geschätzten Kunden unübertroffene Unterstützung zu bieten.',
              value1: {
                title: 'Innovation',
                description:
                  'Wir schätzen Innovation, indem wir ständig kreative Lösungen für Herausforderungen im Energiemanagement suchen. Zum Beispiel helfen unsere hochmodernen KI-Algorithmen einem Fertigungsbetrieb, den Energieverbrauch um 20% zu reduzieren und sowohl Ressourcen als auch Geld zu sparen.'
              },
              value2: {
                title: 'Nachhaltigkeit',
                description:
                  'Nachhaltigkeit leitet jede unserer Entscheidungen. Als Beispiel haben wir in unseren Büros ein umfassendes Recycling-Programm implementiert, um Abfall zu reduzieren und unseren ökologischen Fußabdruck zu minimieren.'
              },
              value3: {
                title: 'Kundenorientiert',
                description:
                  'Unsere Kunden stehen immer an erster Stelle. Zum Beispiel haben wir rund um die Uhr Support bereitgestellt, um sicherzustellen, dass die Energieversorgung eines Krankenhauses während einer kritischen Phase zuverlässig blieb. Damit zeigen wir unser unerschütterliches Engagement für den Erfolg unserer Kunden.'
              }
            }
            // ...
          },
          title: 'Cosmos',
          description:
            'Verwandeln Sie Ihr Wohnhaus in einen intelligenten Knotenpunkt der Konnektivität mit unserem All-in-One-Edge-Gerät - entwickelt für das moderne Zeitalter.',
          footer: {
            our_mission: 'Unsere Mission',
            mission:
              'Unsere Mission ist es, den Immobiliensektor mit innovativen, kostengünstigen Lösungen auszustatten, die zu nachhaltigen Geschäftspraktiken beitragen. Wir sind bestrebt, den CO2-Ausstoß zu reduzieren, die Umweltverantwortung zu fördern und die Rentabilität durch unsere einzigartige Plattform zu stärken.'
          }
        }
      },
      en: {
        translation: {
          hausable: 'Hausable',
          navbar: {
            dashboard: 'Dashboard',
            pages: 'Pages',
            modules: 'Modules',
            documentation: 'Documentation',
            about: 'About Us',
            contact: 'Contact',
            inventory: 'Inventory',
            sales: 'Sales',
            api: 'API',
            guides: 'Guides',
            product: 'Product',
            product1: 'Product 1',
            product2: 'Product 2',
            cosmos: 'Cosmos',
            team: 'Team',
            careers: 'Careers',
            blog: 'Blog'
          },
          landing: {
            current_language: 'English',
            becomeProButton: 'Become a pro',
            bring: 'Bring ',
            adjectives: ['intelligence', 'efficiency', 'control'],
            toYourBuilding: 'to your buildings',
            transformApartmentBuilding:
              "Get an overview of your buildings' energy efficiency with our comprehensive all-in-one solution, <span>Cosmos</span>. Transform your heating system into a data-driven system.",
            ctaText:
              '<span>Take the leap</span> into the future of property management, today.',
            requestdemo: {
              title: 'Request a demo',
              name: {
                label: 'Name',
                placeholder: 'Your Name'
              },
              email: {
                label: 'Email',
                placeholder: 'Your Email'
              },
              message: {
                label: 'Message',
                placeholder: 'Tell us why you are interested in a demo.'
              },
              ctaTextDemo: 'Request a Demo'
            },
            playIcon: 'play',
            imgAltText: 'Landing Banner',
            features: {
              title: 'Energy efficiency and heating system control made easy',
              subtitle:
                'Navigating complexity with ease, our solution offers a comprehensive and intuitive approach to building management, focused on sustainability and regulatory compliance.',
              monitor: {
                icon: 'chart-line',
                iconText: 'MONITOR',
                title: 'Comprehensive Heating Management Solution',
                description:
                  'Our all-in-one solution takes your heating management into the future, focusing on energy monitoring, visualization, and optimization of buildings.'
              },
              predict: {
                icon: 'bell',
                iconText: 'PREDICT',
                title: 'Rule-Based Notification System',
                description:
                  'We provide a rule-based notification system, helping you identify potential issues promptly and act before they escalate.'
              },
              manage: {
                icon: 'wrench',
                iconText: 'MANAGE',
                title: 'Building Management for Sustainability',
                description:
                  'Ensure compliance, optimize system performance, and make informed decisions for future sustainability planning with our comprehensive toolset.'
              },
              assist: {
                icon: 'globe',
                iconText: 'ASSIST',
                title: 'Navigating New Carbon Regulations with Ease',
                description:
                  'With accurate monitoring of CO2 emissions and identification of measures to reduce emissions, we help landlords navigate the new landscape of carbon pricing confidently.'
              }
            },
            services: {
              title: 'Delivering <span>value</span> to you',
              subtitle:
                'We provide features and services that make the complex simple, giving you control over your building’s energy management while ensuring regulatory compliance.',
              cloudMonitoring: {
                icon: 'chart-line',
                title: 'Cloud-based Building Management & Visualization',
                description:
                  'Navigate the complexity of various heating systems with ease using our cloud-based platform. Monitor and visualize energy consumption in real-time.'
              },
              predictiveMaintenance: {
                icon: 'bell',
                title: 'Rule-Based Notification System for Maintenance',
                description:
                  'Our system provides a rule-based notification system, helping you foresee potential issues based on the data collected from your building’s heating system and other sensors.'
              },
              erp: {
                icon: 'dollar-sign',
                title: 'Cost Recovery and Tenant Communication',
                description:
                  "Save money by optimizing energy use, and share the system's cost with tenants as part of their maintenance expenses. Foster open communication with tenants about energy consumption and costs."
              }
            }
          },
          about: {
            title: 'Energy Efficiency, Empowering Sustainability',
            subtitle: 'Learn more about our journey and team',
            companyDescription: {
              title: 'Company Overview',
              story: {
                title: 'Our Story',
                text: 'Welcome to Hausable, your partner in energy efficiency and sustainability. As industry leaders in Energy Management Systems (EMS), we deliver <span>innovative solutions</span> tailored to your unique needs. Our mission is to optimize energy consumption, reduce costs, and minimize environmental impact. At Hausable, we combine cutting-edge technology with deep sector-specific expertise. From manufacturing to education, we empower businesses to make <span>data-driven</span> decisions that drive growth. Join us on the journey to a <span>greener</span>, more efficient future, where sustainability meets profitability'
              },
              mission: {
                title: 'Our Mission',
                text: 'Our mission is to transform energy management. We empower businesses in various sectors to optimize energy efficiency, reduce costs, and embrace sustainable practices.'
              },
              vision: {
                title: 'Our Vision',
                text: "Our vision is to be a global leader in Energy Management Systems, driving sustainability and profitability across industries with innovation and dedication. We're working towards a world where smart energy choices define success."
              }
            },
            team: {
              title: 'Our Team',
              members: {
                member1: {
                  name: 'Sarah Johnson',
                  position: 'Senior Project Manager',
                  bio: 'Sarah is a highly skilled project manager with over 10 years of experience in overseeing complex projects from initiation to successful completion. She is known for her exceptional organizational skills, meticulous attention to detail, and ability to lead cross-functional teams.'
                },
                member2: {
                  name: 'John Smith',
                  position: 'Software Engineer',
                  bio: 'John is a passionate software engineer with a strong background in full-stack web development. He has a knack for turning complex ideas into elegant, user-friendly software solutions. John is a lifelong learner who stays up-to-date with the latest programming languages and technologies.'
                },
                member3: {
                  name: 'Emily Rodriguez',
                  position: 'Marketing Specialist',
                  bio: 'Emily is a creative and data-driven marketing specialist who excels in developing and executing marketing strategies that drive brand awareness and customer engagement. With her keen eye for design and her analytical mindset, she has successfully managed numerous marketing campaigns across various digital platforms.'
                },
                member4: {
                  name: 'David Johnson',
                  position: 'Financial Analyst',
                  bio: 'David is a detail-oriented financial analyst with expertise in budgeting and financial forecasting. He has a knack for identifying cost-saving opportunities and optimizing financial processes.'
                },
                member5: {
                  name: 'Michael Wilson',
                  position: 'Sales Manager',
                  bio: 'Michael is a results-driven sales manager with a proven ability to lead sales teams to success. He specializes in devising effective sales strategies and building strong client relationships.'
                },
                member6: {
                  name: 'Andrew Mitchell',
                  position: 'Data Scientist',
                  bio: 'Andrew is a data scientist with a passion for uncovering valuable insights from complex data sets. He excels in machine learning and statistical analysis, making data-driven decisions a breeze.'
                },
                member7: {
                  name: 'Benjamin Turner',
                  position: 'Product Manager',
                  bio: 'Benjamin is a seasoned product manager known for his strategic vision and leadership. He has successfully launched numerous products and is dedicated to delivering top-notch user experiences.'
                },
                member8: {
                  name: 'Christopher White',
                  position: 'Financial Advisor',
                  bio: 'Christopher is a knowledgeable financial advisor who helps clients navigate their financial goals. His expertise lies in wealth management, retirement planning, and investment strategies.'
                },
                member9: {
                  name: 'Daniel Harris',
                  position: 'Software Developer',
                  bio: 'Daniel is a skilled software developer who loves tackling complex coding challenges. He is proficient in multiple programming languages and has a keen eye for detail.'
                }
              }
            },
            values: {
              title: 'Our Guiding Principles',
              subtitle:
                'At the heart of our mission lies a steadfast commitment to delivering pioneering solutions that harness the power of innovation, a deep-seated sense of environmental responsibility, and an unwavering dedication to providing unparalleled support to our valued clients.',
              value1: {
                title: 'Innovation',
                description:
                  'We value innovation by constantly seeking creative solutions to energy management challenges. For instance, our cutting-edge AI algorithms help a manufacturing plant reduce energy consumption by 20%, saving both resources and money.'
              },
              value2: {
                title: 'Sustainability',
                description:
                  'Sustainability guides our every decision. As an example, we have implemented a comprehensive recycling program in our offices, reducing waste and minimizing our environmental footprint.'
              },
              value3: {
                title: 'Client-Centric',
                description:
                  "Our clients come first, always. For instance, we provided round-the-clock support to ensure a hospital's energy systems remained reliable during a critical period, demonstrating our unwavering commitment to our clients' success."
              }
            }
          },
          title: 'Cosmos',
          description:
            'Transform your apartment building into a smart hub of connectivity with our all-in-one edge device - designed for the modern age.',
          footer: {
            our_mission: 'Our Mission',
            mission:
              'Our mission is to equip the real estate sector with innovative, cost-effective solutions that contribute to their sustainable business practices. We are committed to reducing carbon emissions, promoting environmental responsibility, and bolstering profitability through our unique platform.'
          }
        }
      }
    }
  });

export default i18n;
