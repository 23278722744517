import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { capitalize } from 'helpers/utils';

const NavbarDropdown = ({ title, route }) => {
  return (
    <div className="navbar-dropdown">
      <Link to={route} className="nav-link fw-semi-bold">
        {capitalize(title)}
      </Link>
    </div>
  );
};

NavbarDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default NavbarDropdown;
