import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import className from 'classnames';
import Section from 'components/common/Section';
import CardValues from './CardValues';
import SectionHeader from '../landing/SectionHeader';
import AppContext from 'context/Context';

const Values = () => {
  const { t } = useTranslation();

  const { ref, inView } = useInView({
    triggerOnce: false // Change this to false if you want the animation to trigger again whenever it comes in view
  });

  const {
    config: { isDark }
  } = useContext(AppContext);

  const visibilityClassName = inView ? 'landing-show' : 'service-hidden';

  const valuesList = [
    {
      media: {
        icon: 'lightbulb',
        color: 'warning',
        className: 'fs-4'
      },
      title: t('about.values.value1.title'),
      description: t('about.values.value1.description')
    },
    {
      media: {
        icon: 'recycle',
        color: 'info',
        className: 'fs-5'
      },
      title: t('about.values.value2.title'),
      description: t('about.values.value2.description')
    },
    {
      media: {
        icon: 'users',
        color: 'success',
        className: 'fs-6'
      },
      title: t('about.values.value3.title'),
      description: t('about.values.value3.description')
    }
  ];

  return (
    <Section className="text-center position-relative">
      <SectionHeader subtitle={t('about.values.subtitle')}>
        <h2>{t('about.values.title')}</h2>
      </SectionHeader>
      <Row className="mt-6" ref={ref}>
        {valuesList.map((value, index) => (
          <Col
            lg={4}
            className={className({ 'mt-6 mt-lg-0': index > 0 })}
            key={index}
          >
            <CardValues
              {...value}
              index={index}
              visibilityClassName={visibilityClassName}
            />
          </Col>
        ))}
      </Row>
      <div className={!isDark && 'divider-left'}></div>
      <div className={!isDark && 'divider-right'}></div>
    </Section>
  );
};

export default Values;
