import React, { useContext } from 'react';
import { getPartnerList } from 'data/partner/partnerList';
import Section from 'components/common/Section';
import { Row, Col, Image } from 'react-bootstrap';
import AppContext from 'context/Context';

const Partner = props => (
  <Col xs={3} sm="auto" className="my-1 my-sm-3 px-x1">
    <Image className="landing-cta-img" {...props} alt="Partner" />
  </Col>
);

const Partners = () => {
  const {
    config: { isDark }
  } = useContext(AppContext);

  const partnerList = getPartnerList(isDark);

  return (
    <Section className="py-0 shadow-sm">
      <Row className="flex-center">
        {partnerList.map((partner, index) => (
          <Partner key={index} {...partner} />
        ))}
      </Row>
    </Section>
  );
};

export default Partners;
