import React from 'react';
import { Row, Col } from 'react-bootstrap';
import className from 'classnames';
import Section from 'components/common/Section';
import CardService from './CardService';
import SectionHeader from './SectionHeader';
import { useTranslation, Trans } from 'react-i18next';
import { specialColors } from 'helpers/utils';
import { useInView } from 'react-intersection-observer';

const Services = () => {
  const { t } = useTranslation();

  const { ref, inView } = useInView({
    triggerOnce: false // Change this to false if you want the animation to trigger again whenever it comes in view
  });

  const visibilityClassName = inView ? 'landing-show' : 'service-hidden';

  const serviceList = [
    {
      media: {
        icon: t('landing.services.cloudMonitoring.icon'),
        color: 'info',
        className: 'fs-4'
      },
      title: t('landing.services.cloudMonitoring.title'),
      description: t('landing.services.cloudMonitoring.description')
    },
    {
      media: {
        icon: t('landing.services.predictiveMaintenance.icon'),
        color: 'success',
        className: 'fs-5'
      },
      title: t('landing.services.predictiveMaintenance.title'),
      description: t('landing.services.predictiveMaintenance.description')
    },
    {
      media: {
        icon: t('landing.services.erp.icon'),
        color: 'danger',
        className: 'fs-6'
      },
      title: t('landing.services.erp.title'),
      description: t('landing.services.erp.description')
    }
  ];

  return (
    <Section bg="light" className="text-center">
      <SectionHeader subtitle={t('landing.services.subtitle')}>
        <Trans
          i18nKey="landing.services.title"
          components={{
            span: <span style={{ color: specialColors.accent }} />
          }}
        />
      </SectionHeader>
      <Row className="mt-6" ref={ref}>
        {serviceList.map((service, index) => (
          <Col
            lg={4}
            className={className({ 'mt-6 mt-lg-0': index > 0 })}
            key={index}
          >
            <CardService
              {...service}
              index={index}
              visibilityClassName={visibilityClassName}
            />
          </Col>
        ))}
      </Row>
    </Section>
  );
};

export default Services;
