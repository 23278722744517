import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import bg2 from 'assets/img/generic/bg-2.jpg';
import Section from 'components/common/Section';
import { useTranslation, Trans } from 'react-i18next';
import { specialColors } from 'helpers/utils';
import { Link } from 'react-router-dom';

const Cta = () => {
  const { t } = useTranslation();

  return (
    <Section
      overlay
      image={bg2}
      position="center top"
      className="light bg-dark"
    >
      <Row className="justify-content-center text-center">
        <Col lg={10}>
          <p className="fs-3 fs-sm-3 text-white">
            <Trans
              i18nKey="landing.ctaText"
              components={{
                span: <span style={{ color: specialColors.accent }} />
              }}
            />
          </p>
          <Button
            as={Link}
            variant="outline-light"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-2 py-2"
            to="demo"
          >
            <span style={{ color: specialColors.accent }}>
              {t('landing.requestdemo.title')}
            </span>
          </Button>
        </Col>
      </Row>
    </Section>
  );
};

export default Cta;
