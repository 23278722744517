import React from 'react';
import NavbarStandard from 'components/pages/landing/NavbarStandard';
import FooterStandard from 'components/pages/landing/FooterStandard';
import AboutHero from './AboutHero';
import CompanyDescription from './CompanyDescription';
import Values from './Values';

const About = () => {
  return (
    <>
      <NavbarStandard />
      <AboutHero />
      <CompanyDescription />
      {/*<TeamIntroduction />*/}
      <Values />
      <FooterStandard />
    </>
  );
};

export default About;
