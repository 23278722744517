import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const AuthToasts = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const success = searchParams.get('success');
  useEffect(() => {
    if (success === 'registration') {
      toast.success(t('auth.registration_success'), {
        theme: 'colored',
        toastId: 'registration_success'
      });
    } else if (success === 'login') {
      toast.success(t('auth.login_success'), {
        theme: 'colored',
        toastId: 'login_success'
      });
    }
  }, [success]);

  return null;
};

export default AuthToasts;
