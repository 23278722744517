import Section from 'components/common/Section';
import { Trans, useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { specialColors } from 'helpers/utils';

const CompanyDescription = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    triggerOnce: false // Change this to false if you want the animation to trigger again whenever it comes in view
  });

  const overviewClassName = inView ? 'landing-show' : 'feature-hidden-right';

  return (
    <div className="py-6" ref={ref}>
      <h2 className={`text-center py-2`}>
        {t('about.companyDescription.title')}
      </h2>
      <Section className="py-0 overflow-hidden light" position="center bottom">
        <Row style={{ transition: 'all 1s', transitionDelay: '2000ms' }}>
          <Col>
            <h4 className={`pt-2 ${overviewClassName}`}>
              {t('about.companyDescription.story.title')}
            </h4>
            <p className={`py-2 ${overviewClassName}`}>
              <Trans
                i18nKey={t('about.companyDescription.story.text')}
                components={{
                  span: <span style={{ color: specialColors.accent }} />
                }}
              />
            </p>
          </Col>
        </Row>
      </Section>
      <Section
        className="py-0 overflow-hidden light"
        position="center bottom"
        bg="#21354D"
      >
        <Row style={{ transition: 'all 1s', transitionDelay: '2000ms' }}>
          <Col>
            <h4 className={`pt-2 ${overviewClassName}`}>
              {t('about.companyDescription.mission.title')}
            </h4>
            <p className={`py-2 fst-italic ${overviewClassName}`}>
              <Trans
                i18nKey={t('about.companyDescription.mission.text')}
                components={{
                  span: <span style={{ color: specialColors.accent }} />
                }}
              />
            </p>
          </Col>
        </Row>
      </Section>
      <Section
        className="py-0 overflow-hidden light"
        position="center bottom"
        bg="#21354D"
      >
        <Row style={{ transition: 'all 1s', transitionDelay: '2000ms' }}>
          <Col>
            <h4 className={`pt-2 ${overviewClassName}`}>
              {t('about.companyDescription.vision.title')}
            </h4>
            <p className={`py-2 fst-italic ${overviewClassName}`}>
              <Trans
                i18nKey={t('about.companyDescription.vision.text')}
                components={{
                  span: <span style={{ color: specialColors.accent }} />
                }}
              />
            </p>
          </Col>
        </Row>
      </Section>
    </div>
  );
};

export default CompanyDescription;
