import React from 'react';
import Section from 'components/common/Section';
import Feature from './Feature';
import SectionHeader from './SectionHeader';
import { isIterableArray } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import illustration1 from 'assets/img/illustrations/features/energy_shadow.png';
import illustration2 from 'assets/img/illustrations/features/notifications_shadow.png';
import illustration3 from 'assets/img/illustrations/features/house_shadow.png';
import illustration4 from 'assets/img/illustrations/features/compliance_shadow.png';

const Features = () => {
  const { t } = useTranslation();

  const featureList = [
    {
      icon: t('landing.features.monitor.icon'),
      iconText: t('landing.features.monitor.iconText'),
      color: 'info',
      title: t('landing.features.monitor.title'),
      description: t('landing.features.monitor.description'),
      image: illustration1
    },
    {
      icon: t('landing.features.predict.icon'),
      iconText: t('landing.features.predict.iconText'),
      color: 'warning',
      title: t('landing.features.predict.title'),
      description: t('landing.features.predict.description'),
      image: illustration2,
      inverse: true
    },
    {
      icon: t('landing.features.manage.icon'),
      iconText: t('landing.features.manage.iconText'),
      color: 'success',
      title: t('landing.features.manage.title'),
      description: t('landing.features.manage.description'),
      image: illustration3
    },
    {
      icon: t('landing.features.assist.icon'),
      iconText: t('landing.features.assist.iconText'),
      color: 'primary',
      title: t('landing.features.assist.title'),
      description: t('landing.features.assist.description'),
      image: illustration4,
      inverse: true
    }
  ];

  return (
    <Section className="features">
      <SectionHeader subtitle={t('landing.features.subtitle')}>
        {t('landing.features.title')}
      </SectionHeader>
      {isIterableArray(featureList) &&
        featureList.map((feature, index) => (
          <Feature
            key={feature.color}
            isFirst={index === 0}
            index={index}
            {...feature}
          />
        ))}
    </Section>
  );
};

export default Features;
