import mss from 'assets/img/logos/microsoft_for_startups.png';
import nvidia from 'assets/img/logos/nvidia-inception.png';
import nvidiadark from 'assets/img/logos/nvidia-inception-dark.png';
import exist from 'assets/img/logos/exist-long.jpg';
import existdark from 'assets/img/logos/exist-long-dark.png';

export const getPartnerList = isDark => [
  { src: mss, height: 50 },
  { src: isDark ? nvidiadark : nvidia, height: 80 },
  { src: isDark ? existdark : exist, height: 100 }
];
